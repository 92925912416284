import { GiftVoucherField, VoucherDeliveryMethod } from '@big-red-group/storefront-common-checkout'
import { IconName } from '@/components/Icons'

export const defaultVoucherFields: GiftVoucherField[] = [
  {
    name: 'gift_to',
    key: 'TO',
    title: 'To',
    placeholder: 'e.g. Mum & Dad.',
    type: 'TEXT',
    required: true,
    minLength: 0,
    maxLength: 200,
    order: 0,
  },
  {
    name: 'gift_message',
    key: 'MESSAGE',
    title: 'Message',
    placeholder: 'Thanks for everything - enjoy this epic experience!',
    type: 'LONGTEXT',
    required: true,
    minLength: 0,
    maxLength: 150,
    order: 1,
  },
  {
    name: 'gift_from',
    key: 'FROM',
    title: 'From',
    placeholder: 'e.g. Bridget',
    type: 'TEXT',
    required: true,
    minLength: 0,
    maxLength: 200,
    order: 2,
  },
]

export const defaultVoucherDeliveryMethods: VoucherDeliveryMethod[] = [
  {
    key: 'ELECTRONIC',
    label: 'Electronic',
    requiresPhysicalAddress: false,
    amount: 0,
    currencyCode: 'AUD',
    default: true,
  },
  {
    key: 'ADR_EXPRESS_POST',
    label: 'Express Post',
    requiresPhysicalAddress: true,
    amount: 1500,
    currencyCode: 'AUD',
    default: true,
  },
  {
    key: 'ADR_STANDARD_POST',
    label: 'Standard Post',
    requiresPhysicalAddress: true,
    amount: 750,
    currencyCode: 'AUD',
    default: true,
  },
]

export const redemptionFaqs = [
  {
    heading: 'How can I find my voucher details?',
    content:
      '<h4>Email Voucher</h4><p>You can find your voucher in your email which will consists of the voucher number and PIN that you will be able to copy and paste into the redeem page.</p><h4>Physical/PDF Voucher</h4><p>You can find your voucher in your physical voucher which will consists of the voucher number and PIN that you will be able to copy and paste into the redeem page.</p>',
  },
  {
    heading: 'Why is my voucher not working?',
    content:
      'Nulla felis mi, commodo in magna vestibulum, rhoncus accumsan diam. Duis in gravida augue, id condimentum eros. Nam fermentum enim eget nisl condimentum, in ultricies nulla mollis. Curabitur tempus vel mauris vehicula sollicitudin. Pellentesque vel pharetra eros. Vivamus a ligula tellus.',
  },
  {
    heading: 'I have other questions',
    content: 'Check out our Help centre or jump on Live Chat with our Customer Experience Team.',
  },
]

export const sessionVouchersKey = 'addedVouchers'

export const REDEMPTION_STEPS: Array<{ label: string; icon: IconName }> = [
  { label: 'Validate voucher', icon: 'tickets' },
  { label: 'Link to account', icon: 'personOutline' },
  { label: 'Redemption complete', icon: 'tick' },
]
